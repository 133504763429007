import React from 'react'
import Layout from '../../components/Layout'
import { HeroSimpleCentered } from '../../components/Global/Hero'
import { InlineLink } from '../../components/Global/Link'

const PrivacyPolicy = () => {
  return (
    <Layout>
      <HeroSimpleCentered>
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          Privacy
          <br className="xl:hidden" />
          <span className="text-yellow-300"> Policy</span>
        </h2>
        <p className="mt-8 text-base leading-6 text-gray-500">
          Revised: 5th June 2020
        </p>
      </HeroSimpleCentered>
      <div className="px-4 mx-auto max-w-2xl pb-32">
        <p className="text-sm sm:text-base leading-6 text-gray-500">
          At Chauffeur Me Ltd (“Company”, “we”, “us”, or “our”) we are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at <InlineLink href="mailto:hello@chauffeurmeltd.com">hello@chauffeurmeltd.com</InlineLink>.
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          When you visit our website <InlineLink to="/">https://www.chauffeurmeltd.com</InlineLink>, and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Sites and our services.
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          This privacy policy applies to all information collected through our website (such as <InlineLink to="/">https://www.chauffeurmeltd.com</InlineLink>), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Services").
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500 font-semibold">
          Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.
        </p>
        <h4 className="text-xl leading-6 font-semibold text-gray-900 mt-8">1. What information do we collect?</h4>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Personal information you disclose to us</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          <strong className="font-semibold">In Short:</strong> <span className="italic">We collect personal information that you provide to us.</span>
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          We collect personal information that you voluntarily provide to us when expressing an interest in obtaining information about us or our products and services, when participating in activities on the Services or otherwise contacting us.
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect can include the following:
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          <strong className="font-semibold">Publicly Available Personal Information.</strong> We collect first name, maiden name, last name, and nickname; email addresses; phone numbers; business email; business phone number; and other similar data.
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.
        </p>
        <h5 className="text-lg leading-6 font-medium text-gray-900 mt-6">Information automatically collected</h5>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          <strong className="font-semibold">In Short:</strong> <span className="italic">Some information — such as IP address and/or browser and device characteristics — is collected automatically when you visit our Services.</span>
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          We automatically collect certain information when you visit, use or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          Like many businesses, we also collect information through cookies and similar technologies.
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          <strong className="font-semibold">Online Identifiers.</strong> We collect cookie identifiers, or others such as the ones used for analytics and marketing; tools and protocols, such as IP (Internet Protocol) addresses; devices; and other similar data.
        </p>
        <h4 className="text-xl leading-6 font-semibold text-gray-900 mt-8">2. Will your information be shared with anyone?</h4>
        <p className="mt-6 text-base leading-6 text-gray-500">
          <strong className="font-semibold">In Short:</strong> <span className="italic">We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</span>
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          We may process or share data based on the following legal basis:
        </p>
        <ul className="mt-3 text-sm sm:text-base leading-6 text-gray-500 list-disc pl-5">
          <li className="mt-3">
            <strong className="font-semibold">Consent:</strong> We may process your data if you have given us specific consent to use your personal information in a specific purpose.
          </li>
          <li className="mt-3">
            <strong className="font-semibold">Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.
          </li>
          <li className="mt-3">
            <strong className="font-semibold">Performance of a Contract:</strong> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
          </li>
          <li className="mt-3">
            <strong className="font-semibold">Legal Obligations:</strong> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
          </li>
          <li className="mt-3">
            <strong className="font-semibold">Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
          </li>
        </ul>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          More specifically, we may need to process your data or share your personal information in the following situations:
        </p>
        <ul className="mt-3 text-sm sm:text-base leading-6 text-gray-500 list-disc pl-5">
          <li className="mt-3">
            <strong className="font-semibold">Vendors, Consultants and Other Third-Party Service Providers.</strong> We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Services, which will enable them to collect data about how you interact with the Services over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes. We have contracts in place with our data processors. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will not share your personal information with any organisation apart from us. They will hold it securely and retain it for the period we instruct.
          </li>
          <li className="mt-3">
            <strong className="font-semibold">Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
          </li>
        </ul>
        <h4 className="text-xl leading-6 font-semibold text-gray-900 mt-8">3. Who will your information be shared with?</h4>
        <p className="mt-6 text-base leading-6 text-gray-500">
          <strong className="font-semibold">In Short:</strong> <span className="italic">We only share information with the following third parties.</span>
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          We only share and disclose your information with the following third parties. We have categorized each party so that you may be easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us.
        </p>
        <ul className="mt-3 text-sm sm:text-base leading-6 text-gray-500 list-disc pl-5">
          <li className="mt-3">
            <strong className="font-semibold">Web and Mobile Analytics</strong><br/>Google Analytics
          </li>
        </ul>
        <h4 className="text-xl leading-6 font-semibold text-gray-900 mt-8">4. Do we use cookies and other tracking technologies?</h4>
        <p className="mt-6 text-base leading-6 text-gray-500">
          <strong className="font-semibold">In Short:</strong> <span className="italic">We may use cookies and other tracking technologies to collect and store your information.</span>
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.
        </p>
        <h4 className="text-xl leading-6 font-semibold text-gray-900 mt-8">5. How long do we keep your information?</h4>
        <p className="mt-6 text-base leading-6 text-gray-500">
          <strong className="font-semibold">In Short:</strong> <span className="italic">We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</span>
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than 1 year.
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
        </p>
        <h4 className="text-xl leading-6 font-semibold text-gray-900 mt-8">6. How do we keep your information safe?</h4>
        <p className="mt-6 text-base leading-6 text-gray-500">
          <strong className="font-semibold">In Short:</strong> <span className="italic">We aim to protect your personal information through a system of organizational and technical security measures.</span>
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the services within a secure environment.
        </p>
        <h4 className="text-xl leading-6 font-semibold text-gray-900 mt-8">7. Do we collect information from minors?</h4>
        <p className="mt-6 text-base leading-6 text-gray-500">
          <strong className="font-semibold">In Short:</strong> <span className="italic">We do not knowingly collect data from or market to children under 18 years of age.</span>
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at <InlineLink href="mailto:hello@chauffeurmeltd.com">hello@chauffeurmeltd.com</InlineLink>.
        </p>
        <h4 className="text-xl leading-6 font-semibold text-gray-900 mt-8">8. What are your privacy rights?</h4>
        <p className="mt-6 text-base leading-6 text-gray-500">
          <strong className="font-semibold">In Short:</strong> <span className="italic">In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</span>
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <InlineLink href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</InlineLink>.
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          <strong className="font-semibold">Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt-out of interest-based advertising by advertisers on our Services visit <InlineLink href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</InlineLink>.
        </p>
        <h4 className="text-xl leading-6 font-semibold text-gray-900 mt-8">9. Data Breach</h4>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          A privacy breach occurs when there is unauthorized access to or collection, use, disclosure or disposal of personal information. You will be notified about data breaches when Chauffeur Me Ltd believes you are likely to be at risk or serious harm. For example, a data breach may be likely to result in serious financial harm or harm to your mental or physical well-being. In the event that Chauffeur Me Ltd becomes aware of a security breach which has resulted or may result in unauthorized access, use or disclosure of personal information Chauffeur Me Ltd will promptly investigate the matter and notify the applicable Supervisory Authority not later than 72 hours after having become aware of it, unless the personal data breach is unlikely to result in a risk to the rights and freedoms of natural persons.
        </p>
        <h4 className="text-xl leading-6 font-semibold text-gray-900 mt-8">10. Controls for Do-Not-Track Features</h4>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.
        </p>
        <h4 className="text-xl leading-6 font-semibold text-gray-900 mt-8">11. Do we Make Updates to this Policy?</h4>
        <p className="mt-6 text-base leading-6 text-gray-500">
          <strong className="font-semibold">In Short:</strong> <span className="italic">Yes, we will update this policy as necessary to stay compliant with relevant laws.</span>
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
        </p>
        <h4 className="text-xl leading-6 font-semibold text-gray-900 mt-8">12. How can you contact us about this policy?</h4>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          If you have questions or comments about this policy, you may email us at <InlineLink href="mailto:hello@chauffeurmeltd.com">hello@chauffeurmeltd.com</InlineLink> or by post to:
        </p>
        <p className="mt-3 text-sm sm:text-base leading-6 text-gray-500">
          <address>
          Chauffeur Me Ltd<br/>
          Unit 1, Glenville Industrial Estate<br/>
          Glenville Road<br/>
          Newtownabbey BT37 0TU<br/>
          United Kingdom
          </address>
        </p>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy